function sendMessage() {
    const $form = $('form#contact-form');
    const url = 'https://script.google.com/macros/s/AKfycbyGkcyLsaLAZfHywb8JXHX_SNMJbS5JlcJ1YSXmOv-XtnpCmjUOJX0cTaAeqBwHwyAshg/exec';
    //const url = 'https://script.google.com/macros/s/AKfycbygow2Uw4MnJcioH3omYurKylxW0c31MbKt4-WCTr_vHCz3PWSOjihjtDkmVYuS2xcXWw/exec';
    //const url = 'https://script.google.com/macros/s/AKfycbyj9SGvCUrYHAJBBzwwYjb8ncEooefDnmpTdqYO5d3Jp6ZV9qO-RSnB5D2vBI4hvnjqAg/exec';
    console.info('Submitting enquiry');
    let btn = $('#submit-contact-form');
    btn.addClass('pending');
    btn.prop('disabled', true);
    btn.text('Sending...');
    var jqxhr = $.ajax({
        url: url,
        method: 'GET',
        dataType: 'json',
        data: $form.serializeObject(),
    })
        .done(() => {
            btn.removeClass('pending');
            btn.addClass('success');
            const subscribe = $('#newsletter_subscribed').is(':checked');
            if (subscribe) {
                const name = $('#name')
                    .val()
                    .split(' ');
                const firstname = name.shift();
                const lastname = name.join(' ');
                const email = $('#email').val();
                subscribeToNewsletter('Website Enquiry', email, {firstname: firstname, lastname: lastname});
            }
            document.getElementById('contact-form').reset();
            btn.text('Your Enquiry Has Been Received');
            console.info('submission success');
            // send conversion event to google
            gtag('event', 'conversion', {'send_to': 'AW-826950867/O3BDCI_G2JkZENOJqYoD'}); 
        })
        .fail(() => {
            btn.removeClass('pending');
            btn.addClass('failure');
            btn.text("We're sorry. Something went wrong.");
            console.error('submission failure');
        })
        .always(() => {
            btn.removeClass('pending');
            console.info('submission complete');
            gtag('event', 'conversion', {'send_to': 'AW-826950867/O3BDCI_G2JkZENOJqYoD'});
        });
    return false;
}

function subscribeToNewsletter(source = 'Website Mailing List', email = $('#subscriber_email').val(), props = {}) {
    const url = new URL('https://api.sender.net/v2/subscribers');

    let btn = $('#subscribe_button');
    btn.addClass('pending');
    btn.prop('disabled', true);
    btn.text('Signing up...');

    let headers = {
        Authorization:
            'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMmU2NGZmMTBhZGQzMDk4ZDBlNTY3MDY4NjIwNzNjNWEwNzljZDUwYWEyOGIyNTc2ZGJmN2Q5NzMyODlmNjczYTg3MzBiYTQ3ZDM3ZTI2MDAiLCJpYXQiOjE2MzM2MzIxNTAuNjUxMDIxLCJuYmYiOjE2MzM2MzIxNTAuNjUxMDI2LCJleHAiOjQ3ODcyMzU3NTAuNjQ4ODgxLCJzdWIiOiIxMTMwMzgiLCJzY29wZXMiOltdfQ.if3BYGy1eZhs2hAwXEhJuz4aR8Z7Lf9LpQhYtvvnP1Zm_SaxweCG0RMS8eEvKC1Je2JindEF6Rvd7slWhfAMVg',
        'Content-Type': 'application/json',
        Accept: 'application/json',
    };

    let data = {
        email: email,
        groups: ['axvO9E'],
        fields: {'{$source}': source},
    };

    if (props.firstname) {
        data.firstname = props.firstname;
    }

    if (props.lastname) {
        data.lastname = props.lastname;
    }

    fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
    }).then((response) => {
        btn.removeClass('pending');
        console.log(response);
        if (response.ok) {
            btn.addClass('success');
            btn.text('Thank You!');
            document.getElementById('sub_form').reset();
        } else {
            response.json().then((response) => {
                btn.text('Oops! ' + response.message);
                btn.addClass('failure');
            });
        }
    });

    return false;
}
