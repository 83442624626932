import $ from 'jquery';
import 'slick-carousel';
import 'swiper/css';
import { Swiper } from 'swiper';

// Make jQuery available globally
window.jQuery = window.$ = $;

(function ($) {
    "use strict";

    function loader() {
        function hideLoader() {
            $('#ctn-preloader').addClass('loaded');
            if ($('#ctn-preloader').hasClass('loaded')) {
                $('#preloader').delay(900).queue(function () {
                    $(this).remove();
                });
            }
        }

        if (document.readyState === 'complete') {
            hideLoader();
        } else {
            $(window).on('load', hideLoader);
        }
    }
    loader();

    /** TODO
        $('#mobile-menu').meanmenu({
            meanMenuContainer: '.mobile-menu',
            meanScreenWidth: "991"
        });
    **/
    $('.side-toggle').on('click', function () {
        $('.side-mobile-menu').addClass('info-open');
        $('.offcanvas-overlay').addClass('overlay-open');
    })

    $('.side-info-close,.offcanvas-overlay').on('click', function () {
        $('.side-mobile-menu').removeClass('info-open');
        $('.offcanvas-overlay').removeClass('overlay-open');
    })

    // data background js start
    $("[data-background").each(function () {
        $(this).css("background-image", "url( " + $(this).attr("data-background") + "  )");
    });

    $("[data-bg-color]").each(function () {

        $(this).css("background-color", $(this).attr("data-bg-color"));

    });
    // data background js end

    $('.header-search > a').on('click', function (event) {
        event.preventDefault();
        $('.search-area').addClass('active');
    });

    $('.search-close').on('click', function (event) {
        event.preventDefault();
        $('.search-area').removeClass('active');
    });

    var wind = $(window);
    var sticky = $('#sticky_header');
    wind.on('scroll', function () {
        var scroll = wind.scrollTop();
        if (scroll < 200) {
            sticky.removeClass('sticky_header');
        } else {
            sticky.addClass('sticky_header');
        }
    });

    // slider start
    var swiperOptions = {
        loop: true,
        speed: 1000,
        autoplay: {
            delay: 6500,
            disableOnInteraction: false
        }
    };

    new Swiper(".hero_slider.solartek_slider", swiperOptions);
    // slider end

    // testimonial start
    $('.tm_details_carousel').slick({
        dots: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        asNavFor: '.tm_details_carousel_nav'
    });
    $('.tm_details_carousel_nav').slick({
        dots: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        focusOnSelect: true,
        verticalSwiping: true,
        asNavFor: '.tm_details_carousel',
        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-long-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-long-arrow-right"></i></button>',
        responsive: [
            {
                breakpoint: 1370,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    });

    //  testimonial two
    $('.tm_carousel').slick({
        dots: true,
        arrows: false,
        slidesToShow: 1,
        focusOnSelect: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
    });
    // testimonial end

    // brand slide start
    $('.brand_slide').slick({
        dots: false,
        arrows: false,
        slidesToShow: 5,
        focusOnSelect: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    });
    // brand slide end

    // services slide start
    $('.dl_items-carousel').slick({
        dots: false,
        arrows: true,
        slidesToShow: 4,
        focusOnSelect: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-long-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-long-arrow-right"></i></button>',
        responsive: [
            {
                breakpoint: 1201,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });
    // services slide end

    // gallery active start
    $('.gallery_post-active').slick({
        dots: false,
        arrows: true,
        slidesToShow: 1,
        focusOnSelect: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-long-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-long-arrow-right"></i></button>',
    });
    // gallery active end

    // Accordion Box start
    if ($(".accordion-box").length) {
        $(".accordion-box").on("click", ".acc-btn", function () {
            var outerBox = $(this).parents(".accordion-box");
            var target = $(this).parents(".accordion");

            if ($(this).next(".acc-content").is(":visible")) {
                $(this).removeClass("active");
                $(this).next(".acc-content").slideUp(300);
                $(outerBox).children(".accordion").removeClass("active-block");
            } else {
                $(outerBox).find(".accordion .acc-btn").removeClass("active");
                $(this).addClass("active");
                $(outerBox).children(".accordion").removeClass("active-block");
                $(outerBox).find(".accordion").children(".acc-content").slideUp(300);
                target.addClass("active-block");
                $(this).next(".acc-content").slideDown(300);
            }
        });
    }
    // Accordion Box end

})(jQuery); // Once DOM is loaded